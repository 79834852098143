<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="650px"
    >
      <div class="examRule_head flex">
        <span
          >审核{{
            message.status == 1 ? "中" : message.status == 2 ? "成功" : "失败"
          }}详情</span
        >
        <i class="el-icon-circle-close" @click="close(1)"></i>
      </div>
      <div class="interview-detail">
        <template v-for="(item, index) in message.content">
          <div class="" :key="index">
            <div class="tit">报销{{ index + 1 }}</div>
            <div class="detail-item m-b-10 m-t-10">
              <span class="color-9">报销金额：</span>{{ item.price }}元
            </div>
            <div class="detail-item m-b-10 m-t-10">
              <span class="color-9">报销内容：</span>{{ item.content }}
            </div>
            <div class="detail-item m-b-10 m-t-10">
              <span class="color-9">图片：</span>
              <div class="item-flex-img">
                <!-- <img
                  class="img"
                  v-for="(em, ind) in item.image"
                  :key="ind"
                  :src="em"
                /> -->
                <el-image 
                  style="width: 120px; height: 120px"
                  :src="item.image[0]" 
                  :preview-src-list="item.image">
                </el-image>
              </div>
            </div>
          </div>
        </template>

        <div class="tit" v-if="message.status != 1">审批人信息</div>
        <div class="detail-item" v-if="message.status != 1">
          <div class="detail-li">
            <span class="color-9">审批人：</span>{{ message.audit_name }}
          </div>
          <div class="detail-li">
            <span class="color-9">工号：</span>{{ message.audit_job_number }}
          </div>
          <div class="detail-li">
            <span class="color-9">职位：</span
            >{{ getPosition(message.audit_position) }}
          </div>
        </div>
        <div class="tit">其他信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">申请时间：</span>{{ message.createtime }}
          </div>
          <div class="detail-li">
            <span class="color-9">合计：</span>{{ message.total_price }}元
          </div>
          <div class="detail-li" v-if="message.status == 2">
            <span class="color-9">通过时间：</span>{{ message.updatetime }}
          </div>
          <template v-if="message.status == 3">
            <div class="detail-li">
              <span class="color-9">失败时间：</span>{{ message.updatetime }}
            </div>
            <div class="detail-li">
              <span class="color-9">失败原因：</span>{{ message.audit_remark }}
            </div>
          </template>
        </div>
        <div class="audit-detail-bot" v-if="message.status == 1">
          <el-button class="audit-detail-btn red" @click="changeStatus(1)">拒绝</el-button>
          <el-button class="audit-detail-btn" @click="changeStatus(2)">同意</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="isShow"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>拒绝原因</span>
        <i class="el-icon-circle-close" @click="close(2)"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form
            ref="form"
            :rules="rules"
            class="form"
            :model="form"
            label-width="100px"
          >
          <el-form-item label="" label-width="0" prop="remark">
            <div class="audit-text">
              <el-input
              
                type="textarea"
                :rows="5"
                placeholder="请输入拒绝原因"
                v-model="form.remark"
              >
              </el-input>
            </div>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      isShow:false,
      form:{
        id:null,//
        remark:''
      },
      dialogVisible: false,
      message: {}, //详情
       rules:{
        remark:[
          { required: true, message: '请输入拒绝原因', trigger: 'blur' },
        ]
      }
    };
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
         if (valid) {
          this.$axios.rejectBaoXiao(this.form).then(res=>{
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$emit('succend')
            this.isShow = false
            this.dialogVisible = false;
          })
         } else {
           return false;
         }
    })
      }, 
    changeStatus (type) {
      if (type == 1) {
        this.isShow = true
      } else {
        this.$axios.agreeBaoXiao({id:this.form.id}).then(res=>{
          this.form.remark = ''
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$emit('succend')
          this.dialogVisible = false;
        })
      }
    },
    //关闭
    close(type) {
      
      type == 1 ? this.dialogVisible = false : this.isShow = false
    },
    //打开
    open(id) {
      this.form.id = id
      this.$axios.baoXDetail({ id }).then((res) => {
        this.dialogVisible = true;
        this.message = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.audit-detail-bot {
  display: flex;
  justify-content: space-around;
  padding: 30px 0px;
}
.audit-detail-btn {
  width: 240px;
  height: 56px;
  border-radius: 8px;
  padding: 0px;
  line-height: 56px;
  text-align: center;
  background: #3273f6;
  font-size: 18px;
  color: #fff;
  border: none;
}
.audit-detail-btn.red {
  background: #fddfdf;
  color: #ff5151;
}
.item-flex-img {
  display: flex;
  flex-wrap: wrap;
  .img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin: 0 5px 5px 5px;
  }
}
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.interview-detail {
  padding: 10px 30px 20px 30px;
  .tit {
    font-size: 18px;
    margin: 20px 0 10px 0px;
  }
  .detail-item {
    display: flex;
    flex-wrap: wrap;
  }
  .detail-li {
    width: 50%;
    margin: 10px 0px;
    font-size: 16px;
  }
}
.message-pop {
  box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;
  .pop-hd {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 18px;
    }
    .hd-price {
      font-size: 18px;
    }
    .size-20 {
      font-size: 20px;
    }
    .sex {
      font-size: 16px;
      margin: 0 5px;
    }
    .tag {
      line-height: 28px;
      padding: 0 10px;
      border-radius: 3px;
      background: #d5e3ff;
      font-size: 14px;
      color: #3273f6;
      margin: 0 5px;
    }
    .tag.orange {
      background: rgba(255, 117, 42, 0.1);
      color: #ff752a;
    }
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
/deep/ .el-form-item {
  width: 100%;
}
.audit-text {
  /deep/ .el-textarea__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
</style>
