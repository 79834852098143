<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div @click="changeStatus(index)" class="li" :class="{active:statusIndex == index}" v-for="(item,index) in 3" :key="index">{{ index == 0 ? '审核中' : index == 1 ? '审核成功' : '审核失败' }}</div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="changeType(0)" class="li nav-link" :class="{active:typeIndex == 0}">我的审核</div>
        <div v-if="userInfo.position != 4 && userInfo.position != 5" @click="changeType(1)" class="li nav-link" :class="{active:typeIndex == 1}">审核他人</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--audit-list start-->
      <div class="audit-list" v-if="typeIndex == 0">
        <!--audit-item start-->
        <div class="audit-item" v-for="item in list" :key="item.id">
          <div class="item-l">
            <div class="item-flex">
              <div class="item-td">
                <span class="color-9">申请时间：</span>{{ item.createtime }}
              </div>
              <div class="item-td" v-if="statusIndex != 0">
                <span class="color-9"
                  >{{ statusIndex == 1 ? "通过" : "失败" }}时间：</span
                >{{ item.updatetime }}
              </div>
              <div class="item-td">
                <span class="color-9">报销金额：</span>{{ item.total_price }}元
              </div>
              
            </div>
            <div class="item-td">
                <span class="color-9">报销内容：</span>{{ item.content }}
              </div>
          </div>
          <div class="item-r">
            <div class="color_blue" @click="$refs.epop.open(item.id)">查看详情>></div>
          </div>
        </div>
        <!--audit-item end-->
      </div>
      <!--audit-list end-->

      <!--record_table start-->
      <div class="record_table" v-else>
        <div
          class="record_tr"
          v-for="(item, index) in list"
          :key="index"
         
        >
          <div class="record_td">
            <div class="td">
              <span class="color-9">申请时间：</span>{{ item.createtime }}
            </div>
            <div class="td"><span class="color-9">申请人：</span>{{ item.sub_name }}</div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color-9"></span>
            </div>
            <div class="td"><span class="color-9">工号：</span>{{ item.sub_job_number }}</div>
          </div>
          <div class="record_td">
            <div class="td"><span class="color-9">报销金额：</span>{{ item.total_price }}元</div>
            <div class="td"><span class="color-9">职位：</span>{{ getPosition(item.sub_position) }}</div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color_blue"  @click="$refs.epop.open(item.id)">查看详情>></span>
            </div>
          </div>
        </div>
      </div>
      <!--record_table end-->

      <div class="paging" v-if="list.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--ind-main end-->
    <!-- 借还记录未还清弹框 S -->
    <exp-pop ref="epop" @succend="init"></exp-pop>
    <!-- 借还记录未还清弹框 E -->
  </div>
</template>

<script>
import ExpPop from "../../components/ExpPop";
import { mapGetters } from "vuex";
export default {
  components: { ExpPop },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      statusIndex: 0, //1 审核中；2 审核通过；3 审核失败
      typeIndex: 0, //身份：1 我的；2 他人
    };
  },
  methods: {
    changePage (e) {
      this.page = e
      this.getbaoXList()
    },
    //切换身份
    changeType (index) {
      this.typeIndex = index
      this.init()
    },
    //切换状态
    changeStatus (index) {
      this.typeIndex = 0
      this.statusIndex = index
      this.init()
    },
    init () {
      this.page = 1
      this.list = []
      this.getbaoXList()
    },
    //获取报销列表
    getbaoXList() {
      this.$axios
        .baoXList({
          page: this.page,
          status: this.statusIndex + 1,
          role_type: this.typeIndex + 1,
        })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
  },
  created() {
    this.getbaoXList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.message-select {
  /deep/ .el-select {
    width: 100px;
    min-width: 100px !important;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    cursor: pointer;
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}
.ind-main {
  background: #fff;
  padding: 10px 0;
}
.color_blue {
  color: #3273f6;
  font-size: 16px;
  cursor: pointer;
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-flex {
      display: flex;
      flex-wrap: wrap;
    }
    .item-td {
      font-size: 16px;
      margin: 5px 0;
      line-height: 1.6;
      min-width: 40%;
    }
    .item-l {
      width: calc(100% - 150px);
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:nth-child(2n) {
    background: #f7fcff;
  }
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-flex {
      display: flex;
      flex-wrap: wrap;
    }
    .item-td {
      font-size: 16px;
      margin: 5px 0;
      line-height: 1.6;
      min-width: 30%;
    }
    .item-l {
      width: calc(100% - 150px);
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:nth-child(2n) {
    background: #f7fcff;
  }
}
</style>
